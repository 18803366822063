import {AM} from "../uhod/AM"
import { BCP } from "../uhod/BCP"
import { BMAACP } from "../uhod/BMAACP"
import { CAT } from "../uhod/CAT"
import { ECP } from "../uhod/ECP"
import { FMP } from "../uhod/FMP"
import { LOAD } from "../uhod/LOAD"
import { Masks} from "../uhod/Masks"
import { PM } from "../uhod/PM"
import { SACM } from "../uhod/SACM"
import { SC } from "../uhod/SC"
import { SCWUVF } from "../uhod/SCWUVF"
import React, {useState} from "react"
import burger from "../img/menu-svgrepo-com.png"

function Uhod(){

    const [isOpen, setOpen] = useState();

    return( <div id="strelka">
        <div className="uhod">
            <nav className={`uhod__nav ${isOpen ? "active" : ""}`}>
              <ul className="uhod__nav-list">
                <li className="uhod__nav-item">
                  <a href="#am" className="a2">Альгинатные маски</a>
                </li>
                <li className="uhod__nav-item">
                  <a href="#bcp" className="a2">Средства для ухода за телом</a>
                </li>
                <li className="uhod__nav-item">
                  <a href="#bmaacp" className="a2">Средства для массажа тела и антицеллюлитные средства</a>
                </li>
                <li className="uhod__nav-item">
                  <a href="#cat" className="a2">Очищающие и тонизирующие средства</a>
                </li>
                <li className="uhod__nav-item">
                  <a href="#ecp" className="a2">Средства для ухода за кожей вокруг глаз</a>
                </li>
                <li className="uhod__nav-item">
                  <a href="#fmp" className="a2">Средства для массажа лица</a>
                </li>
                <li className="uhod__nav-item">
                  <a href="#load" className="a2">Линия активных препаратов</a>
                </li>
                <li className="uhod__nav-item">
                  <a href="#masks" className="a2">Маски</a>
                </li>
                <li className="uhod__nav-item">
                  <a href="#pm" className="a2">Пептидные маски</a>
                </li>
                <li className="uhod__nav-item">
                  <a href="#sacm" className="a2">Скрабы и очищающие маски</a>
                </li>
                <li className="uhod__nav-item">
                  <a href="#sc" className="a2">Специальные кремы</a>
                </li>
                <li className="uhod__nav-item">
                  <a href="#scwuvf" className="a2">Специальные кремы с УФ-фильтрами</a>
                </li>
              </ul>
            </nav>
            <button className="uhod__menu-button" onClick={()=> setOpen(!isOpen)}>
              <img src={burger} alt="" />
            </button>
        </div>
        
        <div id="am" className="uhod">
        <AM />
        <BCP />
        <BMAACP />
        <CAT />
        <ECP />
        <FMP />
        <LOAD />
        <Masks />
        <PM />
        <SACM />
        <SC />
        <SCWUVF />
        </div>
        
        <a href="#strelka" className="strelka">
          ▲
        </a>
  </div> )  
}

export {Uhod}