import React, {useState} from "react"
import burger from "../img/menu-svgrepo-com.png"

function Header({ handleClick }){

    const [isOpen, setOpen] = useState();

    return (<header>
        <button onClick={() => handleClick('pole')}>
            <h2>Kosmetologoff</h2>
        </button>
        <div className="header">
            <nav className={`header__nav ${isOpen ? "active" : ""}`}>
              <ul className="header__nav-list">
                <li className="header__nav-item">
                    <button onClick={() => handleClick('uslugi')} className="onclick">
                        услуги
                    </button>
                </li>
                <li className="header__nav-item">
                    <button onClick={() => handleClick('uhod')} className="onclick">
                        уходовая косметика
                    </button>
                </li>
                <li className="header__nav-item">
                    <button onClick={() => handleClick('about_us')} className="onclick">
                        записаться / О нас
                    </button>
                </li>
                <li className="header__nav-item">
                    <button onClick={() => handleClick('otzivi')} className="onclick">
                        Отзывы
                    </button>
                </li>
              </ul>
            </nav>
            <button className="header__menu-button" onClick={()=> setOpen(!isOpen)}>
              <img src={burger} alt="" />
            </button>
        </div>
    </header>)
}

export {Header}