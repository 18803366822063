import {Esk} from "../uslugi/Esk"
import {Ink} from "../uslugi/Ink"
import {Massage} from "../uslugi/Massage"
import {Appk} from "../uslugi/Appk"
import React, {useState} from "react"
import burger from "../img/menu-svgrepo-com.png"

function Uslugi(){

  let esk = <Esk />;
  let ink = <Ink />;
  let massage = <Massage />;
  let appk = <Appk />;
  const [isOpen, setOpen] = useState();

  return( <div id="strelka">
    <div className="uslugi">
            <nav className={`uslugi__nav ${isOpen ? "active" : ""}`}>
              <ul className="uslugi__nav-list">
                <li className="uslugi__nav-item">
                  <a href="#esk" className="a">Эстетическая косметология</a>
                </li>
                <li className="uslugi__nav-item">
                  <a href="#ink" className="a">Инъекционная косметология</a>
                </li>
                <li className="uslugi__nav-item">
                  <a href="#massage" className="a">Массажи</a>
                </li>
                <li className="uslugi__nav-item">
                  <a href="#appk" className="a">Аппаратная косметология</a>
                </li>
              </ul>
            </nav>
            <button className="uslugi__menu-button" onClick={()=> setOpen(!isOpen)}>
              <img src={burger} alt="" />
            </button>
        </div>
    
    
    <div id="esk" className="uslugi">
      {esk}
    </div>
    <div id="ink" className="uslugi">
      {ink}
    </div>
    <div id="massage" className="uslugi">
    {massage}
    </div>
    <div id="appk" className="uslugi">
    {appk}
    </div>
    <a href="#strelka" className="strelka">
      ▲
    </a>
  </div>)
}

export {Uslugi}